// Add your js(x) component file imports/React setup here
import React from 'react';
import ReactRender from '../tools/react-render';

const FinderEl = document.querySelector('[data-behaviour="finder"]');

if (FinderEl) {
    const Finder = React.lazy(() => import('./Finder'));
    ReactRender(
        <Finder/>,
        FinderEl,
    );
}

const DiscoverEl = document.querySelectorAll('[data-behaviour="discover-search"]');

if( DiscoverEl.length > 0 ) {
    
    DiscoverEl.forEach((discoverSearch) => {
        const Discover = React.lazy(() => import('./Discover'));
        ReactRender(
            <Discover url={ discoverSearch.getAttribute('data-url') ?? null } />,
            discoverSearch,
        );
    });
}
